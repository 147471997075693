package xyz.qwewqa.relive.simulator.core.gen

actual val valuesGenDress: Map<Int, GenDress> by lazy {
  loadJsMasterData(dataGenDress) { id, data ->
    GenDress(
        attack_type = data.attack_type.unsafeCast<Int>(),
        attribute_id = data.attribute_id.unsafeCast<Int>(),
        auto_skill1_id = data.auto_skill1_id.unsafeCast<Int>(),
        auto_skill1_release_rank = data.auto_skill1_release_rank.unsafeCast<Int>(),
        auto_skill1_type = data.auto_skill1_type.unsafeCast<Int>(),
        auto_skill2_id = data.auto_skill2_id.unsafeCast<Int>(),
        auto_skill2_release_rank = data.auto_skill2_release_rank.unsafeCast<Int>(),
        auto_skill2_type = data.auto_skill2_type.unsafeCast<Int>(),
        auto_skill3_id = data.auto_skill3_id.unsafeCast<Int>(),
        auto_skill3_release_panel_number = data.auto_skill3_release_panel_number.unsafeCast<Int>(),
        auto_skill3_release_rank = data.auto_skill3_release_rank.unsafeCast<Int>(),
        auto_skill3_type = data.auto_skill3_type.unsafeCast<Int>(),
        auto_skill4_id = data.auto_skill4_id.unsafeCast<Int>(),
        auto_skill4_release_panel_number = data.auto_skill4_release_panel_number.unsafeCast<Int>(),
        auto_skill4_release_rank = data.auto_skill4_release_rank.unsafeCast<Int>(),
        auto_skill4_type = data.auto_skill4_type.unsafeCast<Int>(),
        auto_skill5_id = data.auto_skill5_id.unsafeCast<Int>(),
        auto_skill5_release_panel_number = data.auto_skill5_release_panel_number.unsafeCast<Int>(),
        auto_skill5_release_rank = data.auto_skill5_release_rank.unsafeCast<Int>(),
        auto_skill5_type = data.auto_skill5_type.unsafeCast<Int>(),
        base_agi = data.base_agi.unsafeCast<Int>(),
        base_atk = data.base_atk.unsafeCast<Int>(),
        base_hp = data.base_hp.unsafeCast<Int>(),
        base_mdef = data.base_mdef.unsafeCast<Int>(),
        base_pdef = data.base_pdef.unsafeCast<Int>(),
        base_rarity = data.base_rarity.unsafeCast<Int>(),
        chara_id = data.chara_id.unsafeCast<Int>(),
        command_skill1_id = data.command_skill1_id.unsafeCast<Int>(),
        command_skill2_id = data.command_skill2_id.unsafeCast<Int>(),
        command_skill3_id = data.command_skill3_id.unsafeCast<Int>(),
        command_unique_skill_id = data.command_unique_skill_id.unsafeCast<Int>(),
        confusion_skill_id = data.confusion_skill_id.unsafeCast<Int>(),
        cost = data.cost.unsafeCast<Int>(),
        cri = data.cri.unsafeCast<Int>(),
        delta_agi = data.delta_agi.unsafeCast<Int>(),
        delta_atk = data.delta_atk.unsafeCast<Int>(),
        delta_hp = data.delta_hp.unsafeCast<Int>(),
        delta_mdef = data.delta_mdef.unsafeCast<Int>(),
        delta_pdef = data.delta_pdef.unsafeCast<Int>(),
        dex = data.dex.unsafeCast<Int>(),
        dress_episode_id = data.dress_episode_id.unsafeCast<Int>(),
        dress_type = data.dress_type.unsafeCast<Int>(),
        entry_skill_id = data.entry_skill_id.unsafeCast<Int>(),
        eva = data.eva.unsafeCast<Int>(),
        friendship10_reward_adventure_id = data.friendship10_reward_adventure_id.unsafeCast<Double>(),
        friendship15_levelup_adventure_id = data.friendship15_levelup_adventure_id.unsafeCast<Double>(),
        friendship25_reward_adventure_id = data.friendship25_reward_adventure_id.unsafeCast<Double>(),
        friendship30_levelup_adventure_id = data.friendship30_levelup_adventure_id.unsafeCast<Double>(),
        friendship_pattern_id = data.friendship_pattern_id.unsafeCast<Int>(),
        get_voice = data.get_voice.unsafeCast<String>(),
        growth_board1_id = data.growth_board1_id.unsafeCast<Int>(),
        growth_board2_id = data.growth_board2_id.unsafeCast<Int>(),
        growth_board3_id = data.growth_board3_id.unsafeCast<Int>(),
        growth_board4_id = data.growth_board4_id.unsafeCast<Int>(),
        growth_board5_id = data.growth_board5_id.unsafeCast<Int>(),
        growth_board6_id = data.growth_board6_id.unsafeCast<Int>(),
        growth_board7_id = data.growth_board7_id.unsafeCast<Int>(),
        growth_board8_id = data.growth_board8_id.unsafeCast<Int>(),
        growth_board9_id = data.growth_board9_id.unsafeCast<Int>(),
        hit = data.hit.unsafeCast<Int>(),
        model_id = data.model_id.unsafeCast<Int>(),
        name = stringMapFromDynamic(data.name),
        obtain_route_flags = data.obtain_route_flags.unsafeCast<Int>(),
        party_skill_id = data.party_skill_id.unsafeCast<Int>(),
        party_skill_level_id = data.party_skill_level_id.unsafeCast<Int>(),
        party_skill_type = data.party_skill_type.unsafeCast<Int>(),
        personality_id = data.personality_id.unsafeCast<Int>(),
        profile = stringMapFromDynamic(data.profile),
        published_at = data.published_at.unsafeCast<Int>(),
        rarity_up_cost_id = data.rarity_up_cost_id.unsafeCast<Int>(),
        remake_cost_id = data.remake_cost_id.unsafeCast<Int>(),
        role_index = data.role_index.unsafeCast<Int>(),
        trait_id = data.trait_id.unsafeCast<Int>(),
        win_sequence_id = data.win_sequence_id.unsafeCast<Int>(),
        leader_tactics_skill_id = data.leader_tactics_skill_id.unsafeCast<Int>(),
        sub_tactics_skill_id = data.sub_tactics_skill_id.unsafeCast<Int>(),
        _id_ = id,
    )
  }
}
