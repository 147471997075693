@file:Suppress("PropertyName")

package xyz.qwewqa.relive.simulator.core.gen

import kotlinx.serialization.Serializable

@Serializable
data class GenDress(
    val attack_type: Int,
    val attribute_id: Int,
    val auto_skill1_id: Int,
    val auto_skill1_release_rank: Int,
    val auto_skill1_type: Int,
    val auto_skill2_id: Int,
    val auto_skill2_release_rank: Int,
    val auto_skill2_type: Int,
    val auto_skill3_id: Int,
    val auto_skill3_release_panel_number: Int,
    val auto_skill3_release_rank: Int,
    val auto_skill3_type: Int,
    val auto_skill4_id: Int,
    val auto_skill4_release_panel_number: Int,
    val auto_skill4_release_rank: Int,
    val auto_skill4_type: Int,
    val auto_skill5_id: Int,
    val auto_skill5_release_panel_number: Int,
    val auto_skill5_release_rank: Int,
    val auto_skill5_type: Int,
    val base_agi: Int,
    val base_atk: Int,
    val base_hp: Int,
    val base_mdef: Int,
    val base_pdef: Int,
    val base_rarity: Int,
    val chara_id: Int,
    val command_skill1_id: Int,
    val command_skill2_id: Int,
    val command_skill3_id: Int,
    val command_unique_skill_id: Int,
    val confusion_skill_id: Int,
    val cost: Int,
    val cri: Int,
    val delta_agi: Int,
    val delta_atk: Int,
    val delta_hp: Int,
    val delta_mdef: Int,
    val delta_pdef: Int,
    val dex: Int,
    val dress_episode_id: Int,
    val dress_type: Int,
    val entry_skill_id: Int,
    val eva: Int,
    val friendship10_reward_adventure_id: Double,
    val friendship15_levelup_adventure_id: Double,
    val friendship25_reward_adventure_id: Double,
    val friendship30_levelup_adventure_id: Double,
    val friendship_pattern_id: Int,
    val get_voice: String,
    val growth_board1_id: Int,
    val growth_board2_id: Int,
    val growth_board3_id: Int,
    val growth_board4_id: Int,
    val growth_board5_id: Int,
    val growth_board6_id: Int,
    val growth_board7_id: Int,
    val growth_board8_id: Int,
    val growth_board9_id: Int,
    val hit: Int,
    val model_id: Int,
    val name: Map<String, String>,
    val obtain_route_flags: Int,
    val party_skill_id: Int,
    val party_skill_level_id: Int,
    val party_skill_type: Int,
    val personality_id: Int,
    val profile: Map<String, String>,
    val published_at: Int,
    val rarity_up_cost_id: Int,
    val remake_cost_id: Int,
    val role_index: Int,
    val trait_id: Int,
    val win_sequence_id: Int,
    val leader_tactics_skill_id: Int,
    val sub_tactics_skill_id: Int,
    val _id_: Int
)

expect val valuesGenDress: Map<Int, GenDress>
