package xyz.qwewqa.relive.simulator.core.gen

/*
1:
- 0
- 4
- 9
- 15
- 21
- 28
- 35
- 42
- 50
- 57
- 65
- 73
- 81
- 89
- 97
- 106
- 114
- 123
- 132
- 141
- 150
- 159
- 168
- 177
- 186
- 196
- 205
- 215
- 224
- 234
- 244
- 254
- 264
- 274
- 283
- 294
- 304
- 314
- 324
- 334
- 345
- 355
- 365
- 376
- 386
- 397
- 408
- 418
- 429
- 440
- 451
- 461
- 472
- 483
- 494
- 505
- 516
- 527
- 539
- 550
- 561
- 572
- 583
- 595
- 606
- 618
- 629
- 640
- 652
- 663
- 675
- 687
- 698
- 710
- 722
- 733
- 745
- 757
- 769
- 781
- 792
- 804
- 816
- 828
- 840
- 852
- 864
- 876
- 889
- 901
- 913
- 925
- 937
- 949
- 962
- 974
- 986
- 999
- 1011
- 1024
2:
- 0
- 12
- 24
- 37
- 49
- 61
- 74
- 86
- 98
- 110
- 122
- 134
- 147
- 159
- 171
- 183
- 195
- 207
- 219
- 231
- 242
- 254
- 266
- 278
- 290
- 301
- 313
- 325
- 336
- 348
- 360
- 371
- 383
- 394
- 405
- 417
- 428
- 440
- 451
- 462
- 473
- 484
- 496
- 507
- 518
- 529
- 540
- 551
- 562
- 572
- 583
- 594
- 605
- 615
- 626
- 637
- 647
- 658
- 668
- 678
- 689
- 699
- 709
- 719
- 729
- 740
- 749
- 759
- 769
- 779
- 789
- 799
- 808
- 818
- 827
- 837
- 846
- 855
- 864
- 873
- 882
- 891
- 900
- 909
- 917
- 926
- 934
- 942
- 950
- 958
- 966
- 973
- 981
- 988
- 995
- 1002
- 1008
- 1014
- 1019
- 1024

*/

inline val dataGenAccessoryGrow get() = """{"1":[0,4,9,15,21,28,35,42,50,57,65,73,81,89,97,106,114,123,132,141,150,159,168,177,186,196,205,215,224,234,244,254,264,274,283,294,304,314,324,334,345,355,365,376,386,397,408,418,429,440,451,461,472,483,494,505,516,527,539,550,561,572,583,595,606,618,629,640,652,663,675,687,698,710,722,733,745,757,769,781,792,804,816,828,840,852,864,876,889,901,913,925,937,949,962,974,986,999,1011,1024],"2":[0,12,24,37,49,61,74,86,98,110,122,134,147,159,171,183,195,207,219,231,242,254,266,278,290,301,313,325,336,348,360,371,383,394,405,417,428,440,451,462,473,484,496,507,518,529,540,551,562,572,583,594,605,615,626,637,647,658,668,678,689,699,709,719,729,740,749,759,769,779,789,799,808,818,827,837,846,855,864,873,882,891,900,909,917,926,934,942,950,958,966,973,981,988,995,1002,1008,1014,1019,1024]}"""
